<template>
  <component :is="componentName()" class="p-link" v-bind="linkAttributes()">
    <div v-bind="labelAttributes()" v-html="$t(label)" />
    <p-icon v-if="appendIcon" :icon="appendIcon" size="xs" />
  </component>
</template>

<script lang="ts" setup>
const props = defineProps({
  label: {
    type: String,
    default: null,
  },
  target: {
    type: String,
    default: '_self',
    validator: (val: string) => ['_self', '_blank'].includes(val),
  },
  size: {
    type: String,
    default: 'sm',
    validator: (val: string) => ['sm', 'md'].includes(val),
  },
  appendIcon: {
    type: String,
    default: null,
  },
  passive: {
    type: Boolean,
    default: false,
  },
  href: {
    type: String,
    default: null,
  },
  to: {
    type: [String, Object],
    default: null,
  },
})

const componentName = () => {
  return props.passive ? 'span' : resolveComponent('NuxtLink')
}

const linkAttributes = (): { target?: string } => {
  return {
    [props.href ? 'href' : 'to']: props.href || props.to,
    ...(!props.passive && {
      target: props.target,
    }),
  }
}

const labelAttributes = (): { class: Record<string, boolean> } => {
  return {
    class: {
      'p-link__label': true,
      [`p-link__label--${props.size}`]: !!props.size,
    },
  }
}
</script>
